<style>
	.error-body {
		color: #fff;
		text-align: center;
		padding-top: 10%;
	}
	h1 {
		font-size: 70px;
		font-weight: bold;
		letter-spacing: 3px;
	}
	.bg-maintenance {
		height: 200px;
	}
</style>

<template>
	<div>
		<div class="container">
	        <div class="error-body">
	            <h1>503</h1>
	            <p>
	                Hệ thống đang bảo trì để nâng cấp, vui lòng chờ đợi trong giây lát!<br />
	            </p>
	            <img :src="require('@/assets/images/maintenance.png')" class="bg-maintenance" alt="">
	        </div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ['settings'],
		created () {
			jQuery(($) => {
				$('body').addClass('bg-main');
			});
		}
	}
</script>